<template>

  <div :class="'btn sort ' + type + (showed ? ' showed ' : '')"  @click="toggle" ref="elBtn">
  
    <div class="ched">
      {{ name }}
      <i :class="ico"></i>
    </div>
    
    <div class="opts" :style="'max-height: ' + maxHeight + 'px'">
      <template
        v-for="option in options"
        >
        <div 
          @click.stop="click(option)"
          class="opt"
          >
          {{ option.name }}
        </div>        
      </template>
    </div>
 

  </div>
  
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

interface PageProps {
  name: string,
  type?: string,
  ico?: string,
  options: Array<any>,
  maxHeight?: number
}

const props = withDefaults(defineProps<PageProps>(), {
  type: '',
  ico: 'icon-caret-down',
  maxHeight: 300  
})

const router = useRouter();

const emits = defineEmits([
  'click', 'choose'
]);


const showed = ref(false);
const elBtn = ref(null);

const hide = () => {
  if(showed.value){
    showed.value = false;
  }
};

const toggle = () => {
  showed.value = !showed.value;
};



const click = (option) => {
  hide();
  if(option.event)
    // emits(option.event, option)
    emits('choose', option.event, option)
  else if(option.link)
    router.push({
      path: option.link
    })
};
 
 
onClickOutside(elBtn, (event) => {
  if(!showed.value) return;
  hide();
})


</script>

<style scoped lang="scss">


.sort{
  position: relative;
  display: flex;
  align-content: center;
}
.sort.right{
  justify-content: flex-end;
}
.sort .hnt{

}
.sort .ched{  
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
  width: 100%;
  justify-content: center;
}
.sort .opts{
  display: none;
  position: absolute;
  right: 0;
  top: 35px;
  background-color: var(--un-background-color-gray);
  z-index: 100;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 140px;
  color: var(--gd-text-color);
  overflow-y: auto;

}
.sort.wide .opts{
  width: 100%;
}
.sort.showed .opts{
  display: initial;
}

.sort .opts .opt{
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.sort .opts .opt:hover{
  background-color: var(--un-background-color-gray-light);
} 
</style>
